/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* global window, Navigation, MobileHelpers */
window.Navigation = {
  options: {
    offset: $('[data-js-header]').outerHeight(),
    anchors: $('[data-local-nav-menu] a[href*=\\#]'),
    isMobile: MobileHelpers.usingMobileView(),
  },

  setupScrollToAnchor(options) {
    // detach events first if previously added
    options.anchors.off('click');
    options.anchors.on('click', function(event) {
      const href = $(this).attr('href');
      // This scroll to logic applies only to anchors in current page
      if (href[0] === '#' || window.location.pathname === href.split('#')[0]) {
        event.preventDefault();
        const hash = $(this).prop('hash');

        if (!hash) return;

        Navigation.updateLocation(hash);
        Navigation.scrollToTargetWithOffset(window.location.hash, $('[data-js-header-wrap]').outerHeight() + 8);
      }
    });
  },

  scrollToAnchorOnLoad(options) {
    const offset = (options ? options.offset : undefined) || this.options.offset;
    if (window.location.hash && window.location.hash && window.location.hash.lastIndexOf('#addsearch', 0) !== 0) {
      // addsearch adds an anchor to url, so we ignore it
      setTimeout(() => {
        this.scrollToTargetWithOffset(window.location.hash, offset);
      }, 500);
    }
  },

  updateLocation(hash) {
    if (window.history.pushState) {
      window.history.replaceState(null, null, hash);
    } else {
      window.location.hash = hash;
    }
  },

  scrollToTargetWithOffset(hash, offset) {
    let $target;
    let scrollTopValue;
    try {
      $target = $(hash);
      if ($target.length === 0) {
        $target = $(`a[name="${hash.substr(1)}"]`);
      }
    } catch (err) {
      $target = [];
    }

    if ($target.length === 0) return;

    const _offset = offset || this.options.offset;
    const $root = $('html, body');

    if (hash === '#title') {
      scrollTopValue = 0;
    } else {
      scrollTopValue = $target.offset().top - _offset;
    }

    $root.animate({ scrollTop: scrollTopValue }, 800, () =>
      setTimeout(function() {
        if (Navigation.options.isMobile) {
          $('[data-js-header]').removeClass('is-shy');
        }
      }, 30),
    );
  },

  init(options) {
    const o = options || this.options;
    this.scrollToAnchorOnLoad(o);
    this.setupScrollToAnchor(o);
  },
};

// initialize with default for data-local-navs on plugin load
window.Navigation.init();
