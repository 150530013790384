/**
 * @todo refactor code to at least pass the linter;
 */
import "./worldmap.scss";
import "./assets/network-map.svg"; // map illustration
import { select, selectAll } from "global/utils/dom";
import locationItemTemplate from "./templates/location-item.hbs";
import svgPulseTemplate from "./templates/svg-pulse-animation-circles.hbs";

/* eslint-disable */
$(window).on("load", function() {
  /* There is no front-end test coverage over this yet so we may as well disable this in frontend tests until we have test coverage */
  if (window.AblyTestEnvironment) { return; }

  const $worldmap = $("[data-worldmap]");
  let dataCenters;

  if ($worldmap.length > 0) {
    setupSvg();
    $("[data-group-pin]").click(function() {
      $(".c-worldmap__pin--selected").removeClass("c-worldmap__pin--selected");
      $(this).addClass("c-worldmap__pin--selected");

      var selectedRegion = $(this)
        .closest("[data-datacenter-region]")
        .attr("id")
        .replace(/^pin--datacenter--region-/, "");
      var dc = dataCenters.find(function(dc) {
        return dc.region_id === selectedRegion;
      });

      $worldmap.find("[data-js='pulse-animation']").addClass("hide");
      $(
        `#pin--datacenter--region-${selectedRegion} [data-js='pulse-animation']`
      ).removeClass("hide");

      updateCardWithDataCenterDetails(dc);
    });

    /**
     * @desc get datacenters list from local endpoint find fastest afterwards
     * @note filter dc without region_id which is require for us to
     * build the ping url. More at newtork_helper.rb#33
     */
    $.get(`${window.location.origin}/network/datacenters-list`).done(data => {
      dataCenters = data.filter(dc => dc.region_id !== null);
      getDataCenterLatencies();
    });
    getClosestPop();
  }

  /**
   * @note Parse exported network map svg to add necessary
   * selectors for javascript interaction component styling
   * and animation
   *
   * @note dependends on layer naming convention
   * @note we can probably do this with nokogiri
   * but it shouldn't be that heavy and you guessed
   * i'm not that good at nokogiri :p
   * @todo point to docs of those conventions
   */
  function setupSvg() {
    const worldmap = select("[data-worldmap]");
    const datacentersContainer = select("#network-map__datacenters", worldmap);
    const popsContainer = select("#network-map__pops", worldmap);
    const datacentersPins = selectAll(
      "[id^='pin--datacenter']",
      datacentersContainer
    );
    const popsPins = selectAll('[id^="pin--pop--"]', popsContainer);

    datacentersPins.forEach(pin => {
      // add animation svg template
      pin.setAttribute("data-datacenter-region", "");
      var pinShape = select('[id^="pin-shape"]', pin);
      var pinBBox = pinShape.getBBox();
      var pinCenter = {
        x: pinBBox.x + pinBBox.width / 2,
        y: pinBBox.y + pinBBox.height / 2
      };
      pinShape.classList.add("c-worldmap__group-pin");
      pinShape.setAttribute("data-group-pin", ""); // legacy purposes update me
      $(pin).prepend(svgPulseTemplate({ center: pinCenter }));
    });

    popsPins.forEach(pin => pin.classList.add("c-worldmap__pop"));
    // without this circles and other svg elements are insert in dom
    // but not rendered by the browsers, find a better way please
    // https://stackoverflow.com/a/3642265/2801012
    // hack - https://stackoverflow.com/a/36305466/2801012
    $("[data-worldmap-wrapper] svg").html(
      $("[data-worldmap-wrapper] svg").html()
    );
  }

  /**
   * @desc asynchronous function to ping our datacenters and get latency value
   * @note [1] - Usage of jquery.Deferred to acutally know when
   * that recursive call as ended, allowing us to know when
   * in fact all pings to all datacenters ended and only then
   * update the ui accodingly.
   *    - https://api.jquery.com/Deferred.promise/
   *    - https://stackoverflow.com/a/12283019
   */
  function getDataCenterLatencies() {
    // loaders
    const latencySkeletons = selectAll(
      '[data-skeletonize-loading="latency"] .is-skeletonized',
      $worldmap[0]
    );
    // an array of "kind of" promises (deferred objects)
    // so we can signal completion later and use with $.when
    // [1] https://stackoverflow.com/a/12283019
    const dcPromises = dataCenters.map(function(dc, idx) {
      const pingUrl = `https://${dc.region_id}-rest.ably.io/time`;
      const deferred = $.Deferred(); // [1]

      $.get(pingUrl).then(function() {
        /* Initial connection completed, so we have now eliminated the handshake time */
        var latencyChecks = 0;

        /* average the latency over 5 pings */
        var checkLatency = function() {
          var time = Date.now();
          $.get(pingUrl).then(function() {
            var latency = Date.now() - time;
            if (!dc.latency || dc.latency > latency) {
              dc.latency = latency;
            }

            if (latencyChecks < 5) {
              latencyChecks += 1;
              checkLatency();
            } else {
              setLatencyColours(dc);
              deferred.resolve(); // signal completion to this datacenter pings
            }
          });
        };
        checkLatency();
      });

      // return a pending state
      return deferred.promise();
    });

    /**
     * show closest after finish all the pings, instead
     * call it after every ping and override.
     * Even if one of the points fail ping fail, show ayways
     *
     * @param {Function} succesCallback
     * @param {Function} errorCallback
     * @url https://api.jquery.com/jquery.when/
     */
    $.when(...dcPromises).then(() => {
      showClosestDataCenter();
      latencySkeletons.forEach(s => s.classList.remove("is-skeletonized"));
    });
  }

  function updateCardWithDataCenterDetails(selectedDataCenter, closest) {
    $("[data-js='my-latency']")
      .css("color", selectedDataCenter.colour)
      .text(selectedDataCenter.latency + "ms");
    $("[data-js='latency-color']").css("color", selectedDataCenter.colour);
    $("[data-js='datacenter-label']").text(selectedDataCenter.label);
    $("[data-js='no-datacenters']").text(
      `(${selectedDataCenter.count} datacenters)`
    );

    if (!closest) {
      $("[data-js='label-intro-latency']").text("The selected datacenter is:");
    }

    /* used on responsive cases - expand list with other datacenters */
    $("[data-js='other-datacenters']").empty();
    dataCenters.forEach(function(dc) {
      if (dc.region_id !== selectedDataCenter.region_id) {
        $("[data-js='other-datacenters']").append(
          locationItemTemplate({
            colour: dc.colour,
            count: dc.count,
            label: dc.label,
            latency: dc.latency
          })
        );
      }
    });
    // remove loading state
    $('[data-skeletonize-loading="other-datancers"]').removeClass(
      "is-skeletonized"
    );
  }

  /**
    Show the closest datacenter based on latency
  **/
  function showClosestDataCenter() {
    var latencies = dataCenters
      .filter(function(dc) {
        return dc.latency;
      })
      .map(function(dc) {
        return dc.latency;
      });
    var min = Math.min.apply(null, latencies);
    var closestDataCenter = dataCenters.find(function(dc) {
      return dc.latency === min;
    });

    if (closestDataCenter !== null) {
      /* Ensure no pings are selected */
      $("[data-group-pin] .c-worldmap__pin--selected").removeClass(
        "c-worldmap__pin--selected"
      );
      $worldmap.find("[data-js='pulse-animation']").addClass("hide");

      /* Select the closest pin */
      $(`#pin--datacenter--region-${closestDataCenter.region_id}`).addClass(
        "c-worldmap__pin--selected"
      );
      $(
        `#pin--datacenter--region-${
          closestDataCenter.region_id
        } [data-js='pulse-animation']`
      ).removeClass("hide");

      updateCardWithDataCenterDetails(closestDataCenter, true);
    }
  }

  function setLatencyColours(dc) {
    var pinColour;

    if (dc.latency < 50) {
      pinColour = "#ED760A";
    } else if (dc.latency <= 100) {
      pinColour = "#F9A01B";
    } else {
      pinColour = "#50B4F3";
    }

    dc.colour = pinColour;
    $(`#pin--datacenter--region-${dc.region_id} > [id^='pin']`).attr(
      "fill",
      pinColour
    );
    $(
      `#pin--datacenter--region-${dc.region_id} [data-js='pulse-animation']`
    ).css("fill", pinColour);
  }

  /**
   * @desc Get the closest PoP location information by pinging the cloudfront
   * server url, which routes the request to ably.io/network/*, which exposes
   * the necessary custom headers to identify the location.
   * The match is done using the method described bellow and also sets UI text with
   * information about the match, or about the abscense of a match (error state)
   *   [match-method] - the server response includes a custom header X-Amz-Cf-Pop
   *   a string that includes the IATA code: a 3 letters location identifier
   *   code, used by airports. We can then query our list of locations
   *   provided in the response to find a match and get the details.
   * @related https://github.com/ably/website/issues/2739
   */
  function getClosestPop() {
    const url = `https://geoip-rest.ably.io/network/edge-pop-detection?id=${new Date().toISOString()}`;
    const skeletons = selectAll(
      '[data-skeletonize-loading="pops"] .is-skeletonized',
      $worldmap[0]
    );

    $.get(url)
      .done((res, textStatus, jqXHR) => {
        const popResponseHeader = jqXHR.getResponseHeader("X-Amz-Cf-Pop");
        // string includes and appended alphanumeric string, but we only
        // want the IATA code
        const popId = popResponseHeader && popResponseHeader.substr(0, 3);
        const locations = res;
        const popLocation = popId && locations[popId];

        if (popLocation) {
          // [1]
          const mapNode = select(`[id^="pin--pop--${popId}"]`);
          mapNode.classList.add("is-selected");
          setSuccessPopMessages(popLocation);
        } else {
          setFailedPopMessages();
        }
      })
      .fail(() => {
        setFailedPopMessages();
      })
      .always(() => {
        skeletons.forEach(s => s.classList.remove("is-skeletonized"));
      });
  }

  function setSuccessPopMessages(popLocation) {
    $("[data-js='pop-label']").text(
      [
        popLocation.city,
        popLocation.state === "" ? false : popLocation.state,
        popLocation.country
      ]
        .filter(Boolean)
        .join(", ")
    );

    $("[data-js='no-pops']").text(
      `(${popLocation.count} ${
        popLocation.count > 1 ? "endpoints" : "endpoint"
      })`
    );
  }

  function setFailedPopMessages() {
    $("[data-js='pop-label']")
      .addClass("is-empty")
      .text("Unknown from this location");
    $("[data-js='no-pops']").text("");
  }
});
