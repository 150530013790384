/*
	Credits to codyHouse article
	===

	https://codyhouse.co/gem/auto-hiding-navigation
	depends on MobileHelpers.usingMobileView() method
*/
(function() {
    // Utility method to extend defaults with user options
    function extendDefaults(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }

    /*constructor*/
    var AutoHideNav = function (nav, options) {
        var self = this;

        var defaults = {
			//set scrolling variables
			navHeight: $(nav).outerHeight(),
		 	scrolling:false,
			previousTop:0,
			currentTop:0,
			scrollDelta:10,
			scrollOffset:150,
        }

        /* Initialize default options */
        this.options = defaults;
        this.state = false; // 0 off, 1 on

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
          this.options = extendDefaults(this.options, options);
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.autoHideHeader = this.autoHideHeader.bind(this);
        this.checkSimpleNavigation = this.checkSimpleNavigation.bind(this);
        this.detachEvents = this.detachEvents.bind(this);
        this.attachEvents = this.attachEvents.bind(this);
        this.updateNavHeight = this.updateNavHeight.bind(this);
        this.checkState = this.checkState.bind(this);

        this.nav = document.querySelector(nav);
        this.initialize();
    }


    AutoHideNav.prototype.initialize = function () {
        var self = this;

        self.checkState() // turn on or off based on breakpoint
    };


    AutoHideNav.prototype.handleScroll = function() {
        var self = this;
       	if( !self.options.scrolling ) {
			self.options.scrolling = true;
			(!window.requestAnimationFrame)
				? setTimeout(self.autoHideHeader, 250)
				: requestAnimationFrame(self.autoHideHeader);
		}

    };

     AutoHideNav.prototype.autoHideHeader = function() {
     	var self = this;
		var currentTop = $(window).scrollTop();

		self.checkSimpleNavigation(currentTop);

	   	self.options.previousTop = currentTop;
		self.options.scrolling = false;
	}

	AutoHideNav.prototype.checkSimpleNavigation = function(currentTop) {
		var self = this;
		//there's no secondary nav or secondary nav is below primary nav
	    if (self.options.previousTop - currentTop > self.options.scrollDelta) {
	    	//if scrolling up...
	    	self.nav.classList.remove('is-shy');
	    } else if( currentTop - self.options.previousTop > self.options.scrollDelta && currentTop > self.options.scrollOffset) {
	    	//if scrolling down...
	    	self.nav.classList.add('is-shy');
	    }
	}

	AutoHideNav.prototype.updateNavHeight = function(){
		var self = this;
		self.options.navHeight = self.nav.getBoundingClientRect().height;
	}

	AutoHideNav.prototype.detachEvents = function(currentTop) {
		var self = this;

		self.state = false;
		self.nav.classList.remove('is-shy');
		//remove events
		$(window).off('scroll', self.handleScroll);
		$(window).off('resize', self.updateNavHeight)
		// console.log('detaching events')
	}

	// for now just a copy of init
	AutoHideNav.prototype.attachEvents = function(currentTop) {
		var self = this;
		self.state = true;

		//add events
		$(window).on('scroll', self.handleScroll);
		$(window).on('resize', self.updateNavHeight)
		// console.log('attaching events')
	}

	// check state based on mobile breakpoint
	AutoHideNav.prototype.checkState = debounce((function() {
		var self = this,
			isMobile = MobileHelpers.usingMobileView();

		//was initiated on mobile but resized to desktop
		if (!isMobile && self.state) {
			self.detachEvents()
		} else if (isMobile && !self.state) {
			self.attachEvents()
		}
	}), 500);

    window.AutoHideNav = AutoHideNav;
}());


$(document).ready(function(){
	var autoHideNavGlobalInstance = new AutoHideNav('[data-js-header]');

	(function(){
		var isDocumentation = $('body.page-documentation').length > 0;

		$(window).on('resize', function(){
			if (isDocumentation) {
				autoHideNavGlobalInstance.checkState()
			}
		})
	})();
})
