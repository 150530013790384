$(document).ready(function () {
  if ($('.js-load-more-grid').length === 0) return;

  var hiddenGrid = $('.js-load-more-grid[data-customer-batches]'),
      fakeLoadMore = $('.js-load-more-grid [data-load-batch]'),
      batches = parseInt(hiddenGrid.attr('data-customer-batches'), 10),
      curBatch = 1;

  // do nothing if one batch
  if (batches === curBatch) return

  function showMore(){
    var nextBatch = hiddenGrid.find('[data-customers-batch="' + curBatch +  '"]');
    if (nextBatch.length > 0) {
      nextBatch.each(function(index, customer) {
        $(customer).removeClass('is-hidden')
      });
      curBatch++;

      if (batches === curBatch) {
        fakeLoadMore.addClass('u-hidden');
        return;
      }
    }
  }

  fakeLoadMore.on('click', showMore);
});
