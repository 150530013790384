/**
 * Tables sticky headers
 *
 * @note this one size fits all tables
 * as soon as it stops beeing like this, componentize
 * or pass config via data-attrs
 */
import "sticky-table-headers";

$(function initStickyTables() {
  // we need to make sure that this only happens when content ready
  // var offset = $('[data-has-sticky-thead]').outerHeight(true);
  $("[data-has-sticky-thead]").stickyTableHeaders({
    fixedOffset: 56,
    cacheHeaderHeight: true
  });

  $("[data-has-sticky-thead]").on(
    "enabledStickiness.stickyTableHeaders",
    function handleEnabledStickiness(e) {
      e.target.children[0].classList.add("is-sticky");
    }
  );

  $("[data-has-sticky-thead]").on(
    "disabledStickiness.stickyTableHeaders",
    function handleDisabledStickiness(e) {
      e.target.children[0].classList.remove("is-sticky");
    }
  );
});
