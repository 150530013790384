window.ReferralFlashPromos =
  # content - HTML content for the flash
  # color: Optional background color for the alert
  # sessionScope: If true, the flash will be shown on every subsequent page until closed by the user
  displayInfo: (code, content, color, sessionScope) ->
    cookieName = "referral_promo_#{code}"

    if $.cookie(cookieName) isnt 'seen'
      Alert.info content,
        shiftContent: true,
        autoHide: false,
        backgroundColor: color,
        onClosed: ->
          $.cookie cookieName, 'seen'

      if !sessionScope
        $.cookie cookieName, 'seen'

      # Ensure other unnecessary popups / prompts are not shown such as the newsletter sign up
      ReferralFlashPromos.shown = true

  # When this Flash Promo is shown, we typically want to prevent other pop ups / overlays from showing
  # so shown becomes true and lasts for the duration of the page
  shown: false
