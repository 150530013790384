/*
 * Cross-browser get current scrolling distance
 *
 * @param {string} scroller - scroller element, defaults to window if not defined
 * @return {object} x, y - horizontal and vertical distances
 */

function getScrollPos(scroller){
  if (scroller === null || scroller === undefined) {
    scroller = window;
  }

  var x = (scroller.pageXOffset !== undefined)
    ? scroller.pageXOffset
    : (scroller.scrollLeft !== undefined)
      ? scroller.scrollLeft
      : (document.documentElement || document.body.parentNode || document.body).scrollLeft;

  var y = (scroller.pageYOffset !== undefined)
    ? scroller.pageYOffset
    : (scroller.scrollTop !== undefined)
      ? scroller.scrollTop
      : (document.documentElement || document.body.parentNode || document.body).scrollTop;

  return {x: x, y: y};
}