((factory) ->
  if typeof define is "function" and define.amd
    define ['jquery'], factory
  else
    factory jQuery
) ($) ->

  # first check if placeholder attribute is supported
  if $('<input/>').attr('placeholder','')[0].placeholder == undefined
    # detect placeholder on inputs
    $('input[placeholder]')
      .each ->
        $self = $(this)

        if this.value == '' || this.value == $self.attr('placeholder')
          $self.addClass('placeholder')
          this.value = $self.attr('placeholder')

        # clear placeholder before form submit
        $self.closest('form').bind 'submit', (event) ->
          if $self.attr('value') == $self.attr('placeholder')
            $self.attr('value', '')

    $(document)
      .on 'focusin focusout', $('input[placeholder]'), (event) ->
        $self = $(this)
        placeholder = $self.attr('placeholder')

        if event.type == 'focusout' && this.value == ''
          this.value = placeholder
          $self.addClass('placeholder')
        else
          if this.value == placeholder
            this.value = ''
            $self.removeClass('placeholder')
